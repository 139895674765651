import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hiring-resource',
  templateUrl: './hiring-resource.component.html',
  styleUrls: ['./hiring-resource.component.css']
})
export class HiringResourceComponent implements OnInit {
  userId: any;
  role: string;
  dispHR = true;

  constructor(private router: Router) {
   }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userId');
    this.role =  sessionStorage.getItem('role');
    if(this.role == "Readonly")
    {
      this.dispHR = false;
    }
    if (!(this.userId != 'null' && this.userId != 'undefined')) {
      this.router.navigate(['/login']);
    }
  }

}
