import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../app/components/home/home.component';
import { HiringManagerComponent } from '../app/components/hiring-manager/hiring-manager.component';
import { HiringResourceComponent } from '../app/components/hiring-resource/hiring-resource.component';
import { LoginTestComponent } from './components/login-test/login-test.component';
import { GradingQuestionsComponent } from './components/grading-questions/grading-questions.component';
import { SummaryComponent } from './components/summary/summary.component';
import { GradingScreenComponent } from './components/grading-screen/grading-screen.component';
import { LoginComponent } from './components/login/login.component';
import { HistoricEvComponent } from './components/historicEv/historicEv.component';
import { HistoricEvSummaryComponent } from './components/historicEv-Summary/historicEv-Summary.component';
import { GovernanceComponent } from './components/governance/governance.component';
import { FAQComponent } from '../app/components/faq/faq.component';
import { LogoutComponent } from './components/logout/logout.component';
import { AuthGuard } from './_helpers/auth.guard';

const routes: Routes = [
  {
    path: '', pathMatch: 'full', canActivate: [AuthGuard], component: HomeComponent
  },
  {
    path: 'home', canActivate: [AuthGuard], component: HomeComponent
  },
  {
    path: 'login', component: LoginComponent
  },
   {
    path: 'login-test', component: LoginTestComponent 
   },
  {
    path: 'home/hiring-resource', canActivate: [AuthGuard], component: HiringResourceComponent
  },
  {
    path: 'home/hiring-manager', canActivate: [AuthGuard], component: HiringManagerComponent
  },
  {
    path: 'home/grading-questionaire', canActivate: [AuthGuard], component: GradingQuestionsComponent 
  },
  {
    path: 'home/grading-questionaire/summary', canActivate: [AuthGuard], component: SummaryComponent 
  },
  {
    path: 'home/grading-summary', canActivate: [AuthGuard], component: GradingScreenComponent 
  },
  {
    path: 'historic-evaluation', canActivate: [AuthGuard], component: HistoricEvComponent
  },
  {
    path: 'home/historic-summary', canActivate: [AuthGuard],component: HistoricEvSummaryComponent
  },
  {
    path: 'governance', canActivate: [AuthGuard], component: GovernanceComponent
  },
  {
    path: 'faq', canActivate: [AuthGuard], component: FAQComponent
  },
  {
    path: 'logout', component: LogoutComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
