
import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { JobGradingService } from '../../../services/job-grading.service';
//import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';
import { filter,debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-job-posting-form',
  templateUrl: './job-posting-form.component.html',
  styleUrls: ['./job-posting-form.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class JobPostingFormComponent implements OnInit {

  @Input() type: any;

  userInfo: any;
  userId: any;
  gradingDetailsData: any;
  filteredManagerdtls: any[];  
  selectedManagerAdvanced: any[];
  filteredIncubent=[];
 empPositionId: any;
  empRegion:any;
  empDivision:any;
  OrgCodes: any;
  incumbentInfo: any;
  managerId = "";
  selectedManager: any;
  orgCode = "";
  designation = "";
  managerName = "";
  managerPosId = "";
  positionTitle = "";
  managerOrgCode="";
  managerPositionTitle="";
  managerTitle="";
  incumbentPosTitle = "";
  newPositionTitle = "";
  incumbentName = "";
  newIncumbentName = "";
  selMData: any;
  selPData: any;
  empOrgCode = "";
  newEmpOrgCode = "";
  q1Value = "";
  q2Value = "";
  positionData = [];
  managerOrgCodeData=[];
  incubmentData = [];
  managerPositionData=[];
  managerData = [];
  jobFamilyData: any;
  jobGlobalPositionRoleData: any;
  jobSubFamilyData = [];
  selJobFamilyId: any;
  selglobalPositionRoleId:any;
  selJobSubFamilyId: any;
  hrAPIResp: any;
  quesType = "";
  dispContinue = false;
  quesText = "";
  newPositionId = "";
  newManagerOrgcode="";
  newManagerPositionTitle="";
  newEmpRegion="";
  newEmpDivision="";
  operationalManagers="";
  validPositionTitle = true;
  validOperationalManager = true;
  validPositionCountry = true;
  validIncumbentName = true;
  validNewPositionTitle = true;
  validNewIncumbentName = true;
  //validHM = true;
  apiResp: any;
  countries = [];
  role = "";
  positionCountryID:any;
  selectedCountry: any;
  searchidtxt?:string = "";
  
  gradingData = {
    "jobGradingID": "0",
    "languageID": 1,
    "businessUnit": 1,
    "positionID": "",
    "operationalManagerOrgCode":"",
    "operationalManagerPositionTitle":"",
    "operationalManagerName":"",
    "positionDescription": "",
    "isExistingPosition": false,
    "isVacant": true,
    "hrManagerID": "",
    "hrManagerName": "",
    "hrManagerOrgCode": "",
    "hrManagerPositionID": "",
    "hrManagerPositionTitle": "",
    "hiringManagerID": "",
    "hiringManagerName": "",
    "hiringManagerOrgCode": "",
    "hiringManagerPositionID": "",
    "hiringManagerPositionTitle": "",
    "hiringManagerCountry": "US",
    "hiringManagerCompany": "BASF Corporation",
    "inCumbentID": "",
    "inCumbentName": "",
    "inCumbentOrgCode": "",
    "inCumbentPositionID": "",
    "inCumbentPositionTitle": "",
    "inCumbentCountry": "IND",
    "inCumbentCompany": "BASF Corporation",
    "inCumbentRegion":"",
    "inCumbentDivision":"",
    "jobFamily": "",
    "jobSubFamily": "",
    "globalPositionRole":"",
    "positionCountry": "",
    "q1Value": "",
    "q2Value": "",
    "attachmentContent": "",
    "attachmentName": "",
    "attachmentType": "",
    validPositionCountry: true
  }
  uploadedFiles: any[] = [];
  fileName = "";
  fileType = "";
  fileUpload = false;
  filesize = "";
  fileBuffer: any;
  fileObject: any;
  displayUpload = false;
  hideSpinner:boolean=false;
  @ViewChild('d',{static:false}) dd:ElementRef;
  constructor( private router: Router, private _service: JobGradingService, private spinner: NgxSpinnerService, private toastr: ToastrService) {
//public ref: DynamicDialogRef, public config: DynamicDialogConfig,
  }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userId');
    if (this.userId != 'null' && this.userId != 'undefined') {
      this.role = sessionStorage.getItem('role');
      this.spinner.show();
      if (this.role == 'HM') {
        this._service.getUserInfo(this.userId).subscribe(response => {
          this.userInfo = response;
          this.managerName = this.userInfo[0].lastName + ' ' + this.userInfo[0].firstName;
          this.orgCode = this.userInfo[0].orgCode;
          this.designation = this.userInfo[0].positionTitle;
        });
        this.getGradingDetails(this.userId,this.searchidtxt);
        
      } else if (this.role === "HR") {
        this._service.getUserInfo(this.userId).subscribe(response => {
          this.userInfo = response;
        });
        this._service.getHRUserInfo(this.userId).subscribe(response => {
          this.hrAPIResp = response;
          this.hrAPIResp.forEach(h => {
            let eObj = this.managerData.filter(m => m.basfUserID == h.basfUserID);
            h.fullName = h.lastName + ' ' + h.firstName;
            if (eObj.length == 0) {
              this.managerData.push(h);
            }
          })
        });
        this.getGradingDetails(this.userId,this.searchidtxt);
      }
      else if (this.role === "Readonly") {
        this._service.getUserInfo(this.userId).subscribe(response => {
          this.userInfo = response;
        });
        this._service.getHRUserInfo(this.userId).subscribe(response => {
          this.hrAPIResp = response;
          this.hrAPIResp.forEach(h => {
            let eObj = this.managerData.filter(m => m.basfUserID == h.basfUserID);
            h.fullName = h.lastName + ' ' + h.firstName;
            if (eObj.length == 0) {
              this.managerData.push(h);
            }
          })
        });
        this.getGradingDetails(this.userId,this.searchidtxt);
      }
      this.getJobFamilyDetails();
      this.getRoleDetails();
      this.getCountryList();
    } else {
      this.router.navigate(['/login']);
    }
  }

  getMData(event) {
    this.managerId = event.value.basfUserID;
    if (this.managerId) {
      let mObj = this.managerData.filter(m => m.basfUserID == this.managerId);
      this.managerName = mObj[0].lastName + ' ' + mObj[0].firstName;
      this.orgCode = mObj[0].orgCode;
      this.managerPosId = mObj[0].positionID;
      this.designation = mObj[0].positionTitle;
      this.clearValues();
      ////this.validHM = true;
      //this.getGradingDetails(this.managerId);
    } else {
      this.orgCode = "";
      this.designation = "";
    }
  }

  
  manageroperfilter:any;
  timeout;

  filterOperationalMgr(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;
    let searchdata = 1; 
 
    for (let i = 0; i < this.managerPositionData.length; i++) {
      let manageroperfilter = this.managerPositionData[i];
      if (manageroperfilter.managerName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(manageroperfilter);
        searchdata = 2;
      }
    }

   if(query != "" && query.length > 2 && searchdata == 1)
    {
      this.getGradingDetails(this.userId,query);
      for (let i = 0; i < this.managerPositionData.length; i++) {
        let manageroperfilter = this.managerPositionData[i];
        if (manageroperfilter.managerName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(manageroperfilter);       
        }
      }
    }

    this.filteredManagerdtls = filtered;
  
  }

  getGradingDetails(basfUserId,searchID) {
    this.positionData = [];
    this.managerOrgCodeData=[];    
  
    this._service.getGradingDetails(basfUserId,searchID)
    .pipe(
      filter(Boolean),
      debounceTime(4000),
      distinctUntilChanged())
    .subscribe(
       response => {
      if (response) {
        this.hideloader();
    }
      this.gradingDetailsData = response;
      this.gradingDetailsData.forEach((data, index) => {
        let pmObj:any;
        if(data.managerName=='')
        {
          data.managerName='No operational manager maintained';
           pmObj={managerName:data.managerName,id:index};
        }else{
          pmObj={managerName:data.managerName,id:index};
        }

        let pFobj=this.managerPositionData.filter(x=>x.managerName==data.managerName);  
        if(pFobj.length==0)
        {
          this.managerPositionData.push(pmObj);
          //this.countries_.push(pmObj);
        }
        // let pObj = { jobGradingPositionTitle: data.jobGradingPositionTitle, id: index };
        // let fPobj = this.positionData.filter(p => p.jobGradingPositionTitle == data.jobGradingPositionTitle);    
        // if (fPobj.length == 0) {
        //   this.positionData.push(pObj);
        // }
      });
      //this.managerPositionData.sort((a,b) => a.managerName > b.managerName ? 1:-1);
      let nObj = { managerName: 'Not-Assigned', id: this.managerPositionData.length };
      let fNobj = this.managerPositionData.filter(p => p.managerName == nObj.managerName);
      if (fNobj.length == 0) {
        this.managerPositionData.push(nObj);
        //this.countries_.push(nObj);     
      }

      this.managerPositionData.sort((a, b) => a.managerName > b.managerName ? 1 : -1);
      //const arr = ['css', 'js', 'ts'];
  
      const fromIndex = this.managerPositionData.indexOf(
        this.managerPositionData.find(x=>x.managerName==="Not-Assigned")
      ); // 👉️ 0
      const toIndex = 0;
  
      const element = this.managerPositionData.splice(fromIndex, 1)[0];
      //console.log(element); // ['css']
  
      this.managerPositionData.splice(toIndex, 0, element);

      const fromIndex1 = this.managerPositionData.indexOf(
        this.managerPositionData.find(x=>x.managerName==="No operational manager maintained")
      ); // 👉️ 0
      const toIndex1 = 1;
  
      const element1 = this.managerPositionData.splice(fromIndex1, 1)[0];
      //console.log(element); // ['css']
  
      this.managerPositionData.splice(toIndex1, 0, element1);
      //this.filteredCountries = filtered;
       // console.log(this.managerPositionData);

      //console.log(this.managerPositionData); // 👉️ ['js', 'ts', 'c
    })
    
  }

  hideloader() {
  
    // Setting display of spinner
    // element to none
    this.dd.nativeElement.style.display='None'
    this.hideSpinner=true;
}

  getJobFamilyDetails() {
    let unitId = "1";
    let langId = "1";
    this._service.getJobFamilyDetails(langId, unitId).subscribe(response => {
      let apiResp = response;
      this.jobFamilyData = apiResp;
      this.spinner.hide();
    })
  }
  getRoleDetails() {
    let unitId = "1";
    let langId = "1";
    this._service.getgetGlobalPositionRoleDetails(langId, unitId).subscribe(response => {
      let apiResp = response;
      this.jobGlobalPositionRoleData = apiResp;
      this.spinner.hide();
    })
  }

  getJobSubFamilyData() {
    this.jobSubFamilyData = [];
    let fSubFamilyData = this.jobFamilyData.filter(f => f.jobFamilyID == this.selJobFamilyId)[0];
    if (fSubFamilyData.jobSubFamily.length > 0) {
      fSubFamilyData.jobSubFamily.forEach(fSub => {
        this.jobSubFamilyData.push(fSub);
      })
    }

  }
  getCountryList() {
    const positionCountry = 0;
    this._service.getJobCountryDetails(positionCountry).subscribe(response => {
      this.apiResp = response;
      this.countries = this.apiResp;
      //if (this.config.data.edit) {
      //  this.generateCountryObj(this.gradingData.positionCountry);
      //}
    })
  }

  onChangeCountry(event) {
    console.log("CE", event.value);
    this.gradingData.positionCountry = this.selectedCountry.countryID;
    this.checkCountryValidation();
    
  }

  generateCountryObj(countryID) {
    this.selectedCountry = this.countries.find(country => country.countryID == countryID);
    console.log(this.selectedCountry);
  }
  checkCountryValidation() {  
    if(this.gradingData.positionCountry ==""){
      this.gradingData.validPositionCountry =false;      
    }else{
      this.gradingData.validPositionCountry =true;
    }
  }

  clearValues() {
    this.incubmentData = [];
    this.incumbentInfo = null;
    this.empPositionId = "";
    this.empRegion="";
    this.empDivision="";
    this.empOrgCode = "";
    this.incumbentName = "";
    this.newPositionTitle = "";
    this.newIncumbentName = "";
    this.newEmpOrgCode = "";
    this.newPositionId = "";    
    this.newEmpRegion="";
    this.newEmpDivision="";
    this.validPositionTitle = true;
    this.validOperationalManager = true;
    this.validPositionCountry = true;
    this.validIncumbentName = true;
    this.validNewPositionTitle = true;
    this.validNewIncumbentName = true;
    this.selJobSubFamilyId = "";
    this.selJobFamilyId = "";
    this.selglobalPositionRoleId="";
    this.positionCountryID = "";
  }
  clearPositionValues()
  {
    this.positionTitle="";
    this.managerOrgCode="";
    this.managerPositionTitle="";
    this.newManagerOrgcode="";
    this.newManagerPositionTitle="";
    this.positionData=[];
    this.managerOrgCodeData=[];
    this.filteredIncubent=[];
  }

  updateIncubmentData() {
    this.clearValues();
    this.filteredIncubent.forEach((data, index) => {
      if (data.jobGradingPositionTitle === this.positionTitle) {
        this.gradingData.jobGradingID = data.jobGradingID.toString();
        if (data.employeeName != null && data.employeeName != undefined && data.employeeName != "") {
          let empObj = data;
          let fEmpObj = this.incubmentData.filter(i => i.employeeName == data.employeeName && i.employeePositionId == data.jobGradingPositionID && i.employeeOrgCode == data.employeeOrgCode);
          if (fEmpObj.length == 0) {
            this.incubmentData.push(empObj);
          }
        }
        else{
          data.employeeName = 'Vacant - '+data.jobGradingPositionID ;
          let empObj = data;
          let fEmpObj = this.incubmentData.filter(i => i.employeeName == data.employeeName && i.employeePositionId == data.jobGradingPositionID && i.employeeOrgCode == data.employeeOrgCode);
          if (fEmpObj.length == 0) {
            this.incubmentData.push(empObj);
          }
        }
        if (data.employeeCountry != null && data.employeeCountry != undefined && data.employeeCountry != "") {
          this.positionCountryID = this.countries.find(cd => cd.countryName === data.employeeCountry).countryID;
        }
        if (data.globalPositionRole != null && data.globalPositionRole != undefined && data.globalPositionRole != "") {
          this.selglobalPositionRoleId = this.jobGlobalPositionRoleData.find(jf => jf.globalPositionRoleName === data.globalPositionRole).globalPositionRoleID;
        }
        if (data.jobFamily != null && data.jobFamily != undefined && data.jobFamily != "") {
          this.selJobFamilyId = this.jobFamilyData.find(jf => jf.jobFamilyName === data.jobFamily).jobFamilyID;
          if (data.jobSubFamily != null && data.jobSubFamily != undefined && data.jobSubFamily != "") {
            let fSubFamilyData = this.jobFamilyData.filter(f => f.jobFamilyID == this.selJobFamilyId)[0];
            this.getJobSubFamilyData()
            this.selJobSubFamilyId = fSubFamilyData.jobSubFamily.find(jfs => jfs.jobSubFamilyName === data.jobSubFamily).jobSubFamilyID;
          } 
          this.empPositionId = data.jobGradingPositionID;
          this.empRegion = data.region;
          this.empDivision = data.division;
          this.empOrgCode = data.employeeOrgCode;
        }
      }
    })
    this.incubmentData.sort((a,b) => (a.employeeName > b.employeeName) ? 1:-1);
    if (this.incubmentData && this.incubmentData.length == 1) {

      this.incumbentInfo = this.incubmentData[0].employeeName;
      this.getIData();
    }
  }

  public onSearchoperationalManager(term: string): void {
    // `onSearchInputChange` is called whenever the input is changed.
    // We have to send the value to debouncing observable
    
  }

  operationalManager1()
  {
    this.clearPositionValues();
    this.clearValues();
    if(this.gradingDetailsData.length>0)
    {
    this.gradingDetailsData.forEach((data, index) => {
      if (data.managerName === this.manageroperfilter.managerName) {
        this.managerTitle =this.manageroperfilter.managerName;
        this.gradingData.jobGradingID = data.jobGradingID.toString();
        if (data.managerName != null && data.managerName != undefined && data.managerName != "") {
          let empObj = data;
          this.filteredIncubent.push(data);
          let fEmpObj = this.positionData.filter(i =>i.jobGradingPositionTitle===data.jobGradingPositionTitle);
          let mpObj = this.managerOrgCodeData.filter(i=>i.ManagerOrgCode===data.ManagerOrgCodeData);
          if (fEmpObj.length == 0) {
            this.positionData.push(empObj);
          }
          if(mpObj.length==0)
          {
            this.managerOrgCodeData.push(empObj);
          }
        }
      }
      let nObj = { jobGradingPositionTitle: 'None', id: this.positionData.length };
      let fNobj = this.positionData.filter(p => p.jobGradingPositionTitle == nObj.jobGradingPositionTitle);
      if (fNobj.length == 0) {
        this.positionData.push(nObj);
      }
      if(this.managerTitle=="Not-Assigned")
      {
        this.positionTitle="None";
      }
      if (this.managerOrgCodeData && this.managerOrgCodeData.length == 1) {

        this.managerOrgCode = this.managerOrgCodeData[0].managerOrgCode;
        this.managerPositionTitle=this.managerOrgCodeData[0].managerPositionTitle;   

      }
      // this.positionData.sort((a,b) => a.jobGradingPositionTitle > b.jobGradingPositionTitle ? 1:1);

    })
    this.positionData.sort((a,b) => a.jobGradingPositionTitle > b.jobGradingPositionTitle ? 1:-1);
      const fromIndex = this.positionData.indexOf(
        this.positionData.find(x=>x.jobGradingPositionTitle==="None")
        ); // 👉️ 0
      const toIndex = 0;
 
      const element = this.positionData.splice(fromIndex, 1)[0];
      //console.log(element); // ['css']
      //const element=this.positionData.find(x=>x.jobGradingPositionTitle==="None");
 
      this.positionData.splice(toIndex, 0, element);
  }
  else{
    let nObj = { jobGradingPositionTitle: 'None', id: this.positionData.length };
      let fNobj = this.positionData.filter(p => p.jobGradingPositionTitle == nObj.jobGradingPositionTitle);
      if (fNobj.length == 0) {
        this.positionData.push(nObj);
      }
      if(this.managerTitle=="Not-Assigned")
      {
        this.positionTitle="None";
      }
  }
  }

  operationalManager2(event:any)
  {
    this.clearPositionValues();
    this.clearValues();
    if(this.gradingDetailsData.length>0)
    {
    this.gradingDetailsData.forEach((data, index) => {
      if (data.managerName === this.manageroperfilter.managerName) {
        this.managerTitle =this.manageroperfilter.managerName;
        this.gradingData.jobGradingID = data.jobGradingID.toString();
        if (data.managerName != null && data.managerName != undefined && data.managerName != "") {
          let empObj = data;
          this.filteredIncubent.push(data);
          let fEmpObj = this.positionData.filter(i =>i.jobGradingPositionTitle===data.jobGradingPositionTitle);
          let mpObj = this.managerOrgCodeData.filter(i=>i.ManagerOrgCode===data.ManagerOrgCodeData);
          if (fEmpObj.length == 0) {
            this.positionData.push(empObj);
          }
          if(mpObj.length==0)
          {
            this.managerOrgCodeData.push(empObj);
          }
        }
      }
      let nObj = { jobGradingPositionTitle: 'None', id: this.positionData.length };
      let fNobj = this.positionData.filter(p => p.jobGradingPositionTitle == nObj.jobGradingPositionTitle);
      if (fNobj.length == 0) {
        this.positionData.push(nObj);
      }
      if(this.manageroperfilter.managerName=="Not-Assigned")
      {
        this.positionTitle="None";
      }
      if (this.managerOrgCodeData && this.managerOrgCodeData.length == 1) {

        this.managerOrgCode = this.managerOrgCodeData[0].managerOrgCode;
        this.managerPositionTitle=this.managerOrgCodeData[0].managerPositionTitle;   

      }
      // this.positionData.sort((a,b) => a.jobGradingPositionTitle > b.jobGradingPositionTitle ? 1:1);

    })
    this.positionData.sort((a,b) => a.jobGradingPositionTitle > b.jobGradingPositionTitle ? 1:-1);
      const fromIndex = this.positionData.indexOf(
        this.positionData.find(x=>x.jobGradingPositionTitle==="None")
        ); // 👉️ 0
      const toIndex = 0;
 
      const element = this.positionData.splice(fromIndex, 1)[0];
      //console.log(element); // ['css']
      //const element=this.positionData.find(x=>x.jobGradingPositionTitle==="None");
 
      this.positionData.splice(toIndex, 0, element);
  }
  else{
    let nObj = { jobGradingPositionTitle: 'None', id: this.positionData.length };
      let fNobj = this.positionData.filter(p => p.jobGradingPositionTitle == nObj.jobGradingPositionTitle);
      if (fNobj.length == 0) {
        this.positionData.push(nObj);
      }
      if(this.manageroperfilter.managerName=="Not-Assigned")
      {
        this.positionTitle="None";
      }
  }
  }  

  operationalManager()
  {
    this.clearPositionValues();
    this.clearValues();
    if(this.gradingDetailsData.length>0)
    {
    this.gradingDetailsData.forEach((data, index) => {
      if (data.managerName === this.managerTitle) {
        this.gradingData.jobGradingID = data.jobGradingID.toString();
        if (data.managerName != null && data.managerName != undefined && data.managerName != "") {
          let empObj = data;
          this.filteredIncubent.push(data);
          let fEmpObj = this.positionData.filter(i =>i.jobGradingPositionTitle===data.jobGradingPositionTitle);
          let mpObj = this.managerOrgCodeData.filter(i=>i.ManagerOrgCode===data.ManagerOrgCodeData);
          if (fEmpObj.length == 0) {
            this.positionData.push(empObj);
          }
          if(mpObj.length==0)
          {
            this.managerOrgCodeData.push(empObj);
          }
        }
      }
      let nObj = { jobGradingPositionTitle: 'None', id: this.positionData.length };
      let fNobj = this.positionData.filter(p => p.jobGradingPositionTitle == nObj.jobGradingPositionTitle);
      if (fNobj.length == 0) {
        this.positionData.push(nObj);
      }
      if(this.managerTitle=="Not-Assigned")
      {
        this.positionTitle="None";
      }
      if (this.managerOrgCodeData && this.managerOrgCodeData.length == 1) {

        this.managerOrgCode = this.managerOrgCodeData[0].managerOrgCode;
        this.managerPositionTitle=this.managerOrgCodeData[0].managerPositionTitle;   

      }
      // this.positionData.sort((a,b) => a.jobGradingPositionTitle > b.jobGradingPositionTitle ? 1:1);

    })
    this.positionData.sort((a,b) => a.jobGradingPositionTitle > b.jobGradingPositionTitle ? 1:-1);
      const fromIndex = this.positionData.indexOf(
        this.positionData.find(x=>x.jobGradingPositionTitle==="None")
        ); // 👉️ 0
      const toIndex = 0;
 
      const element = this.positionData.splice(fromIndex, 1)[0];
      //console.log(element); // ['css']
      //const element=this.positionData.find(x=>x.jobGradingPositionTitle==="None");
 
      this.positionData.splice(toIndex, 0, element);
  }
  else{
    let nObj = { jobGradingPositionTitle: 'None', id: this.positionData.length };
      let fNobj = this.positionData.filter(p => p.jobGradingPositionTitle == nObj.jobGradingPositionTitle);
      if (fNobj.length == 0) {
        this.positionData.push(nObj);
      }
      if(this.managerTitle=="Not-Assigned")
      {
        this.positionTitle="None";
      }
  }
  }

  getIData() {
    if (this.incumbentInfo) {
      let selIncubment = this.gradingDetailsData.filter(g => g.employeeName === this.incumbentInfo)[0];
      this.empPositionId = selIncubment.jobGradingPositionID;
      this.empRegion=selIncubment.region;
      this.empDivision=selIncubment.division;
      this.empOrgCode = selIncubment.employeeOrgCode;
      this.incumbentName = selIncubment.employeeName;
      this.incumbentPosTitle = selIncubment.positionTitle;
      this.validIncumbentName = true;
      this.positionCountryID = this.countries.find(cd => cd.countryName === selIncubment.employeeCountry).countryID;
      this.selglobalPositionRoleId = this.jobGlobalPositionRoleData.find(jf => jf.globalPositionRoleName === selIncubment.globalPositionRole).globalPositionRoleID;
      this.selJobFamilyId = this.jobFamilyData.find(jf => jf.jobFamilyName === selIncubment.jobFamily).jobFamilyID;
          if (selIncubment.jobFamily != null && selIncubment.jobSubFamily != undefined && selIncubment.jobSubFamily != "") {
            let fSubFamilyData = this.jobFamilyData.filter(f => f.jobFamilyID == this.selJobFamilyId)[0];
            this.getJobSubFamilyData()
            this.selJobSubFamilyId = fSubFamilyData.jobSubFamily.find(jfs => jfs.jobSubFamilyName === selIncubment.jobSubFamily).jobSubFamilyID;
          }

    }
    else {
      this.empPositionId = "";
      this.empRegion="";
      this.empDivision="";
      this.empOrgCode = "";
      this.incumbentName = "";
      this.incumbentPosTitle = "";
      this.validIncumbentName = false;
    }
  }


  modifyQuestionaryType() {
    if (this.q1Value == 'Yes' && this.q2Value == 'Yes') {
      this.quesType = "PMQ";
      this.quesText = " to questionnaire for Project Management positions";
      this.dispContinue = true;
    } else if (this.q1Value == 'No' && this.q2Value == 'Yes') {
      this.quesType = "LSQ";
      this.quesText = " to questionnaire for Leadership positions";
      this.dispContinue = true;
    } else if ((this.q1Value == 'No' && this.q2Value == 'No') || (this.q1Value == 'Yes' && this.q2Value == 'No')) {
      this.quesType = "ICQ";
      this.quesText = " to questionnaire for Individual Contributor positions";
      this.dispContinue = true;
    }
  }

  continue() {
    this.spinner.show();
    this.validIncumbentName = true;
    this.validNewIncumbentName = true;
    this.validPositionTitle = true;
    this.validOperationalManager = true;
    this.validPositionCountry = true;
    this.validNewPositionTitle = true;
    //this.validHM = true;
    if (!this.validateForm()) {
      this.spinner.hide();
    } else {
      this.runAssignValueFuncs();
      this.gradingData.positionCountry =this.countries.find(cd=>cd.countryID==this.positionCountryID).countryName;
      if(this.selglobalPositionRoleId != null && this.selglobalPositionRoleId != undefined && this.selglobalPositionRoleId != "")
      {
        this.gradingData.globalPositionRole=this.jobGlobalPositionRoleData.find(
          gr=>gr.globalPositionRoleID==this.selglobalPositionRoleId).globalPositionRoleName;
      }      
      this.gradingData.q2Value = this.q2Value;
      this.gradingData.q1Value = this.q1Value;
      this.spinner.hide();
      if (this.fileName != "") {
        this.gradingData.attachmentContent = this.fileObject['fileAsBase64'];
        this.gradingData.attachmentName = this.fileObject['fileName'];
        this.gradingData.attachmentType = this.fileObject['fileType'];
      }
      this.router.navigate(['home/grading-questionaire'], { state: { data: { quesType: this.quesType, gradingData: this.gradingData } } });
    }
  }

  runAssignValueFuncs() {
    this.assignValuesBasedOnRole(this.role);
    this.assignValuesBasedOnPosition();
    this.assignJobFamilyValues();
    this.assignValuesBasedOnOpManager();
  }
  assignValuesBasedOnOpManager()
  {
    let caseValue = "3";
    if (this.managerTitle != 'Not-Assigned' && this.managerOrgCodeData.length != 0) caseValue = "1";
    if (this.managerTitle != 'Not-Assigned' && this.managerOrgCodeData.length == 0) caseValue = "2";
    this.switchFuncforMorgPos(caseValue);
  }
  switchFuncforMorgPos(caseValue)
  {
    switch (caseValue) {
      case "1": 
      this.gradingData.operationalManagerOrgCode = this.managerOrgCode;
      this.gradingData.operationalManagerPositionTitle=this.managerPositionTitle;
      this.gradingData.operationalManagerName=this.managerTitle;
       
        break;
      case "2": 
      this.gradingData.isExistingPosition = true;
      this.gradingData.isVacant = true;
      this.gradingData.operationalManagerOrgCode = this.newManagerOrgcode;
      this.gradingData.operationalManagerPositionTitle=this.newManagerPositionTitle;
      this.gradingData.operationalManagerName=this.managerTitle;
        
        break;
      case "3": 
      this.gradingData.jobGradingID = "0";
      this.gradingData.isExistingPosition = false;
      this.gradingData.isVacant = true;
      this.gradingData.operationalManagerOrgCode = this.newManagerOrgcode;
      this.gradingData.operationalManagerPositionTitle=this.newManagerPositionTitle;
      this.gradingData.operationalManagerName=this.managerTitle;
       
        break;
    }
  }

  assignValuesBasedOnRole(role) {
    let assignedRole = role;
    switch (assignedRole) {
      case "HM": this.gradingData.hiringManagerID = this.userInfo[0].basfUserID;
        this.gradingData.hiringManagerName = this.userInfo[0].lastName + ' ' + this.userInfo[0].firstName;
        this.gradingData.hiringManagerOrgCode = this.userInfo[0].orgCode;
        this.gradingData.hiringManagerPositionID = this.userInfo[0].positionID;
        this.gradingData.hiringManagerPositionTitle = this.userInfo[0].positionTitle;
        break;
      case "HR": this.gradingData.hrManagerID = this.userInfo[0].basfUserID;
        this.gradingData.hrManagerName = this.userInfo[0].lastName + ' ' + this.userInfo[0].firstName;
        this.gradingData.hrManagerOrgCode = this.userInfo[0].orgCode;
        this.gradingData.hrManagerPositionID = this.userInfo[0].positionID;
        this.gradingData.hrManagerPositionTitle = this.userInfo[0].positionTitle;
        this.gradingData.hiringManagerID = this.managerId;
        this.gradingData.hiringManagerName = this.managerName;
        this.gradingData.hiringManagerOrgCode = this.orgCode;
        this.gradingData.hiringManagerPositionID = this.managerPosId;
        this.gradingData.hiringManagerPositionTitle = this.designation;
        break;
    }
  }

  assignValuesBasedOnPosition() {
    let caseValue = "3";
    if (this.positionTitle != 'None' && this.incubmentData.length != 0) caseValue = "1";
    if (this.positionTitle != 'None' && this.incubmentData.length == 0) caseValue = "2";
    this.switchFuncforPositions(caseValue);
  }

  switchFuncforPositions(caseValue) {
    switch (caseValue) {
      case "1": this.gradingData.positionDescription = this.positionTitle;
        let posData = this.positionData.filter(p => p.jobGradingPositionTitle === this.positionTitle)[0];
        this.gradingData.positionID = posData.jobGradingPositionID;
        //this.gradingData.
        this.gradingData.inCumbentName = this.incumbentName;
        this.gradingData.inCumbentOrgCode = this.empOrgCode;
        this.gradingData.inCumbentPositionID = this.empPositionId;
        this.gradingData.inCumbentRegion=this.empRegion;
        this.gradingData.inCumbentDivision=this.empDivision;
        this.gradingData.inCumbentPositionTitle = this.incumbentPosTitle;
        this.gradingData.isExistingPosition = true;
        this.gradingData.isVacant = false;
        break;
      case "2": this.gradingData.isExistingPosition = true;
        this.gradingData.isVacant = true;
        this.gradingData.positionDescription = this.positionTitle;
        let posData1 = this.positionData.filter(p => p.jobGradingPositionTitle === this.positionTitle)[0];
        this.gradingData.positionID = posData1.jobGradingPositionID;
        this.gradingData.inCumbentName = this.newIncumbentName;
        this.gradingData.inCumbentOrgCode = this.newEmpOrgCode;
        this.gradingData.inCumbentPositionID = this.newPositionId;
        this.gradingData.inCumbentRegion=this.newEmpRegion;
        this.gradingData.inCumbentDivision=this.newEmpDivision;
        this.gradingData.inCumbentPositionTitle = this.positionTitle;
        break;
      case "3": this.gradingData.jobGradingID = "0";
        this.gradingData.isExistingPosition = false;
        this.gradingData.isVacant = true;
        this.gradingData.positionDescription = this.newPositionTitle;
        this.gradingData.inCumbentName = this.newIncumbentName;
        this.gradingData.inCumbentOrgCode = this.newEmpOrgCode;
        this.gradingData.inCumbentPositionID = this.newPositionId;
        this.gradingData.inCumbentRegion=this.newEmpRegion;
        this.gradingData.inCumbentDivision=this.newEmpDivision;
        this.gradingData.inCumbentPositionTitle = this.newPositionTitle;
        break;
    }
  }
  assignJobFamilyValues() {
    if (this.selJobFamilyId != null && this.selJobFamilyId != undefined && this.selJobFamilyId != "") {
      let jobFamily = this.jobFamilyData.filter(j => j.jobFamilyID == this.selJobFamilyId)[0];
      this.gradingData.jobFamily = jobFamily.jobFamilyName;
      if (this.selJobSubFamilyId != null && this.selJobSubFamilyId != undefined && this.selJobSubFamilyId != "") {
        let jobSubFamily = jobFamily.jobSubFamily.filter(js => js.jobSubFamilyID == this.selJobSubFamilyId)[0];
        this.gradingData.jobSubFamily = jobSubFamily.jobSubFamilyName;
      }
    }
  }

  myUploader(event) {
    this.spinner.show();
    let fileData = event[0];
    const element = event[0];
    this.filesize = element.size;
    this.filesize = this.filesize.toString();
    if (element.size > 3145728) {
      this.spinner.hide();
      this.toastr.warning("File Limit Exceeded, Allows only below 3MB data");
      return false;
    } else {
      this.fileName = fileData.name;
      this.fileType = fileData.type;
      this.fileBuffer = "";
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.fileBuffer = fileReader.result;
        this.fileBuffer = this.fileBuffer.replace(`data:${this.fileType};base64,`, '');
        this.fileUpload = true;
        this.fileObject = {
          "fileName": this.fileName,
          "fileType": this.fileType,
          "fileSize": this.filesize,
          "fileAsBase64": this.fileBuffer,
        };
        console.log("File Object", this.fileObject);
      }
      fileReader.readAsDataURL(fileData);
    }
    this.uploadedFiles.push(event[0]);
    this.spinner.hide();
  }

  removeFile() {
    this.fileName = "";
    this.fileType = "";
    this.filesize = "";
    this.fileBuffer = ""
    this.fileObject = null;
  }

  validateForm() {
    this.role = sessionStorage.getItem('role');
    if (this.role == 'HR') {
      if (this.managerId == "") {
        //this.validHM = false;
      }
    }
    
    if (this.managerTitle == null || this.managerTitle == undefined || this.managerTitle == "") {
      this.validOperationalManager = false;
    }
    if (this.positionTitle == null || this.positionTitle == undefined || this.positionTitle == "") {
      this.validPositionTitle = false;
    }
    if (this.positionCountryID == null || this.positionCountryID == undefined || this.positionCountryID == "") {
      this.validPositionCountry = false;
    }
    if (this.positionCountryID == null || this.positionCountryID == undefined || this.positionCountryID == "") {
      this.validPositionCountry = false;
    }
    if (this.positionTitle != 'None') {
      if (this.incumbentInfo == null || this.incumbentInfo == undefined || this.incumbentInfo == "") {
        this.validIncumbentName = false;
      }
    } else {
      if (this.newPositionTitle == "") this.validNewPositionTitle = false;
      if (this.newIncumbentName == "") this.validNewIncumbentName = false;
    }
    return this.validOperationalManager && this.validPositionTitle && this.validPositionCountry && this.validNewPositionTitle;// && this.validNewIncumbentName;//this.validIncumbentName && this.validHM && 
  }
}
